import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER } from '../../constants';
import { Iresponse } from 'src/app/interfaces/iresponse';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor( private http: HttpClient ) { }

  getInventory() {
    return this.http.get(`${SERVER}inventario`).pipe(
      map(
        (res: Iresponse) => res.ok ? res.result : throwError(new Error(res.error.message || res.error))
      )
    );
  }

  setInventory(inventory) {
    return this.http.post(`${SERVER}inventario`, inventory).pipe(
      map(
        (res: Iresponse) => res.ok ? res.result : throwError(new Error(res.error.message || res.error))
      )
    );
  }

  getDistribution(date) {
    return this.http.get(`${SERVER}inventario/reparto/${date}`).pipe(
      map(
        (res: Iresponse) => res.ok ? res.result : throwError(new Error(res.error.message || res.error))
      ),
      catchError(error => throwError(new Error(error.error || error.message || error)))
    )
  }
}
